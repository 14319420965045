'use client';

import React, { HTMLProps } from 'react';
import NextLink from 'components/Link/Link';
import { Button } from '@mezzoforte/forge';
import { PrismicLink } from '@prismicio/react';

export const wrapButtonWithLink = <LinkProps extends object>(
  LinkComponent: React.ComponentType<LinkProps>
): React.FC<LinkProps & React.ComponentProps<typeof Button> & HTMLProps<HTMLAnchorElement>> =>
  function ButtonLink({ textAlign, ...props }) {
    return (
      <LinkComponent {...(props as LinkProps)} style={{ textDecoration: 'none' }}>
        <Button {...props} textAlign={textAlign} as="span" />
      </LinkComponent>
    );
  };

export const NextLinkButton = wrapButtonWithLink(NextLink);
export const PrismicLinkButton = wrapButtonWithLink(PrismicLink);
