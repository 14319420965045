import React from 'react';
import { Link, Text } from '@mezzoforte/forge';

export const CustomerService: React.FC = () => (
  <Text>
    Puh. <Link href="tel:+358942413200">09 4241 3200</Link>
    <br />
    <Link href="mailto:asiakaspalvelu@huutokaupat.com" className="notranslate">
      asiakaspalvelu@huutokaupat.com
    </Link>
    <br />
    Auki arkisin klo 8–20, la–su klo 12–20
  </Text>
);
