import React, { useState } from 'react';
import { useTokens, Box, TextInput, Button } from '@mezzoforte/forge';

const { colors } = useTokens.huutokaupat;

export interface MfaFormProps {
  onSubmit: (passcode: string) => void;
  isPending: boolean;
  isError: boolean;
}

export const MfaForm: React.FC<MfaFormProps> = ({ onSubmit, isPending, isError }) => {
  const [passcode, setPasscode] = useState('');

  function submitTwoFactor(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit(passcode.replaceAll(/\s+/g, ''));
  }

  return (
    <Box
      as="form"
      backgroundColor={colors.backgroundInfo}
      p={3}
      onSubmit={submitTwoFactor as () => void}
      data-test="two-factor-form"
    >
      <TextInput
        data-test="passcode-field"
        name="passcode"
        label="Anna kaksivaiheisen tunnistautumisen vahvistuskoodi"
        autoComplete="one-time-code"
        autoFocus
        isRequired
        isDisabled={isPending}
        isInvalid={isError}
        errorInfo="Virheellinen vahvistuskoodi"
        value={passcode}
        onInput={e => setPasscode(e.currentTarget.value)}
      />
      <Button mt={3} type="submit" variant="highlight-hero" isLoading={isPending}>
        Tarkista
      </Button>
    </Box>
  );
};
