import { useQuery } from '@tanstack/react-query';
import { CategoriesWithCountsAPIResponse, CategoryAPIResponse } from 'types/ApiResponse';
import { CategoriesDynamicData, CategoryDynamicData } from 'types/Category';

function categoryFromAPIResponse(responseItem: CategoryAPIResponse, order: number): CategoryDynamicData {
  const category: CategoryDynamicData = {
    order,
    id: responseItem.category.id,
    name: responseItem.category.htmlName,
    children: {},
    parentId: responseItem.category.parentCategoryId,
    entryCount: responseItem.count,
  };

  // Create children recursively
  (responseItem.children ?? []).forEach((c, i) => {
    category.children[c.category.id] = categoryFromAPIResponse(c, i);
  });

  return category;
}

export const fetchCategories = () => {
  const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
  return fetch(`${NEXT_PUBLIC_BASE_URL ?? ''}/api/category-list`, {
    next: {
      revalidate: 60 * 10,
    },
  })
    .then<CategoriesWithCountsAPIResponse>(res => res.json())
    .catch<CategoriesWithCountsAPIResponse>(() => {
      console.warn('Fetching category list failed');
      return {
        categories: [],
        counts: {
          Nettihuutokauppa: 0,
          ulosotto: 0,
          kuolinpesa: 0,
          metsahallitus: 0,
          julkinen_sektori: 0,
          puolustusvoimat: 0,
          konkurssipesat: 0,
          rahoitusyhtiot: 0,
          auctions: 0,
          endingToday: 0,
        },
      };
    })
    .then(response => ({
      ...response,
      categories: response.categories.map<CategoryDynamicData>(categoryFromAPIResponse),
    }));
};

export const useCategories = () => {
  return useQuery<CategoriesDynamicData>({
    queryKey: ['categories'],
    queryFn: fetchCategories,
    staleTime: 1000 * 60 * 10,
  });
};
