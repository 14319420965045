import { type PropsWithChildren, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Alert, Button, Modal, ModalBody, Box, Link, Text, Heading, Flex } from '@mezzoforte/forge';
import { useSession } from 'hooks/useSession';
import { gtmService } from 'util/gtm-service';
import type { LoginAPIRequest } from 'types/ApiRequest';
import { ShowResponsive } from 'components/Responsive/ShowResponsive';
import { LoginForm } from './LoginForm';
import { MfaForm } from './MfaForm';
import { Binoculars, Heart, Icon, Notifications, Robot, X } from '@mezzoforte/forge-icons';
import { NextLinkButton } from 'components/Link/LinkButton';
import { CustomerService } from '../CustomerService/CustomerService';
import {
  getWidget as getFreshchatWidget,
  updateSessionIdentifier as updateFreshchatSessionIdentifier,
  updateLoggedInState as updateFreshchatLoggedInState,
} from '../../features/Freshchat/FreschatScript';

interface LoginContentItem {
  heading: React.ReactNode;
  body: React.ReactNode;
  icon: Icon;
}

const content: LoginContentItem[] = [
  {
    heading: 'Muistutukset',
    body: 'Tilaa ilmainen tekstari tuntia ennen kiinnostavan kohteen päättymistä',
    icon: Notifications,
  },
  {
    heading: 'Kohteiden seuranta',
    body: 'Kerää kiinnostavat kohteet omalle listalle, jota on helppo seurata kaikilla laitteillasi.',
    icon: Heart,
  },
  {
    heading: 'Korotusautomaatti',
    body: 'Anna automaatin huutaa puolestasi valitsemaasi kattohintaan asti.',
    icon: Robot,
  },
  {
    heading: 'Hakuvahti',
    body: 'Etsitkö jotain tiettyä? Saat sähköpostin, kun sopivia kohteita tulee myyntiin.',
    icon: Binoculars,
  },
];

export const LoginModal: React.FC<{ isOpen: boolean; onDismiss: () => void; onSuccess: () => void }> = ({
  isOpen,
  onDismiss,
  onSuccess = () => {},
}) => {
  const { login: loginMutation } = useSession();
  const initialFocusRef = useRef(null);
  const [formState, setFormState] = useState<'credentials' | 'mfa'>('credentials');
  const [credentials, setCredentials] = useState({ username: '', password: '' });

  function updateFreshchatProperties() {
    const widget = getFreshchatWidget();
    if (!widget) {
      return;
    }

    updateFreshchatLoggedInState(widget, true);
    if (widget.isOpen()) {
      void updateFreshchatSessionIdentifier(widget);
    }
  }

  const submitLogin = (credentials: LoginAPIRequest) => {
    setCredentials(credentials);
    loginMutation.mutate(credentials, {
      onSuccess: response => {
        if (response.promptTwoFactor) {
          setFormState('mfa');
          return;
        }

        gtmService.recommended.login('Password');
        onSuccess && onSuccess();
        onDismiss && onDismiss();
        updateFreshchatProperties();
      },
    });
  };

  const submitMfa = (passcode: string) => submitLogin({ ...credentials, passcode });

  const onClose = () => {
    loginMutation.reset();
    onDismiss && onDismiss();
  };

  // Not ideal, but we need to manually create a modal header in order to have working password managers.
  // Chakra UI modal steals focus to the close button and this prevents browser addons like 1Password from
  // working correctly. If this issue is fixed upstream there is no longer a need for a custom modal header.
  const ModalHeader: React.FC<PropsWithChildren> = ({ children }) => (
    <Flex justifyContent="space-between" alignItems="center" mb={3} px={4}>
      <Heading variant="h3" as="h2">
        {children}
      </Heading>
      <Button icon={<X />} aria-label="Sulje" onClick={onDismiss} tabIndex={-1} />
    </Flex>
  );

  const LoginErrorAlert: React.FC = () =>
    loginMutation.error?.isPasswordExpired ? (
      <Alert
        variant="danger"
        title="Salasana on vaihdettava ennen kuin voit kirjautua sisään"
        mb={5}
        px={4}
        data-test="auth-alert"
      >
        <Text as="p">Saat vaihdettua salasanasi sähköpostiisi lähetetyn linkin avulla.</Text>
        <Text as="p">Ongelmatilanteissa voit olla yhteydessä asiakaspalveluumme:</Text>
        <CustomerService />
      </Alert>
    ) : (
      <Alert variant="danger" title="Kirjautuminen epäonnistui" mb={5} data-test="auth-alert">
        <Link href="/unohditko-salasanasi">Ongelmia sisäänkirjautumisessa?</Link>
      </Alert>
    );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      initialFocusRef={initialFocusRef}
      variant="custom"
      size={{ base: 'xs', sm: 'sm', lg: 'lg', xl: '4xl' }}
    >
      <ModalHeader>Kirjaudu sisään</ModalHeader>
      <ModalBody>
        {loginMutation.isError && <LoginErrorAlert />}
        <ModalContent>
          <Box>
            {formState === 'credentials' && (
              <LoginForm onSubmit={submitLogin} ref={initialFocusRef} {...loginMutation} />
            )}
            {formState === 'mfa' && <MfaForm onSubmit={submitMfa} {...loginMutation} />}
          </Box>
          <ShowResponsive lg>
            <Heading variant="h5" mb={3}>
              Ei tunnusta vielä?
            </Heading>
            <Text fontSize={14}>
              Tee täysin ilmainen tunnus parissa minuutissa. Pääset huutamaan kohteita ja saat käyttöösi:
            </Text>
            <FeatureList>
              {content.map(({ heading, body, icon: Icon }, index) => (
                <FeatureListItem key={index}>
                  <Flex justifyContent="center">
                    <Icon size={32} />
                  </Flex>
                  <Box>
                    <Heading variant="h5" fontSize={16}>
                      {heading}
                    </Heading>
                    <Text fontSize={12}>{body}</Text>
                  </Box>
                </FeatureListItem>
              ))}
            </FeatureList>
          </ShowResponsive>
        </ModalContent>
      </ModalBody>
      <Flex mt={2} flexDirection="column" alignItems={{ base: 'stretch', lg: 'flex-end' }} px={4}>
        <ShowResponsive lgHide>
          <Text textAlign="center" fontWeight="bold" mt={5} mb={2}>
            Ei tunnusta vielä?
          </Text>
        </ShowResponsive>
        <NextLinkButton href="/tee-tunnus" variant="primary" width="100%" onClick={onClose}>
          Tee ilmainen tunnus
        </NextLinkButton>
      </Flex>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;

const FeatureList = styled.ul`
  list-style: none;
  margin-top: 1rem;
  padding: 0;
`;

const FeatureListItem = styled.li`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 32px 1fr;
  margin-bottom: 1rem;
`;
