import Cookies from 'js-cookie';
import { useState } from 'react';
import { gtmService } from 'util/gtm-service';
import { client } from 'util/apiClient';

export const cookieSettingCategories = ['mandatory', 'marketing', 'personalization', 'statistics', 'other'] as const;
export const consentCookie = 'consent-data-v2';

export type ConsentCategory = (typeof cookieSettingCategories)[number];
export const defaultConsentSettings: ConsentCategory[] = ['mandatory'];
const nonReadOnlyCategories: string[] = [...cookieSettingCategories];
const isConsentCategory = (value: string): value is ConsentCategory => nonReadOnlyCategories.includes(value);
export function getConsentData(cookieName: string = consentCookie, includeDefaults = true) {
  // Comma separated list of accepted consent categories
  const consentDataString = Cookies.get(cookieName);

  // Default consents if cookie not present
  if (!consentDataString) {
    return includeDefaults ? [...defaultConsentSettings] : [];
  }

  return consentDataString.split(',').filter(isConsentCategory);
}

// Includes all possible consents with true/false values
export const getConsentDataObject = () => {
  const consents = getConsentData();
  return cookieSettingCategories.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue]: consents.includes(currentValue),
    }),
    {}
  );
};

export async function saveConsent(consent: ConsentCategory[], origin?: string) {
  const currentConsentData = Object.values(consent).filter(isConsentCategory);

  const consentDataString = currentConsentData.join(',');
  const expiresDate = new Date();
  expiresDate.setMonth(expiresDate.getMonth() + 6);
  Cookies.set(consentCookie, consentDataString, {
    sameSite: 'Lax',
    secure: true,
    expires: expiresDate, // 6 months
  });

  await client
    .post<{
      readonly consent: string;
      readonly consentOrigin: string;
    }>('/consent', {
      consent: consentDataString,
      consentOrigin: origin,
    })
    .catch(e => {
      console.error(e);
    });

  gtmService.updateConsentToGtm(currentConsentData);

  Cookies.remove('consent-data'); // remove old consent-data cookie
}

// Hook for handling consent settings and saving them
export const useConsentSettings = () => {
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [consentSettings, setConsentSettings] = useState<ConsentCategory[]>(getConsentData());
  const oldConsentData = getConsentData('consent-data');

  const dispatch = (value: ConsentCategory | 'all' | 'clear') => {
    if (value === 'all') {
      setConsentSettings([...cookieSettingCategories]);
    } else if (value === 'clear') {
      setConsentSettings([...defaultConsentSettings]);
    } else {
      // Toggle value in array
      setConsentSettings(settings => {
        if (settings.includes(value)) {
          return settings.filter(category => category !== value);
        }
        return [...settings, value];
      });
    }
  };

  const saveSettings = async (origin?: string, skipLoadingState = false) => {
    if (!skipLoadingState) {
      setIsSavingSettings(true);
    }
    await saveConsent(consentSettings, origin);
    if (!skipLoadingState) {
      setIsSavingSettings(false);
    }
  };

  const acceptAll = async (origin?: string, skipLoadingState = false) => {
    if (!skipLoadingState) {
      setIsSavingSettings(true);
    }
    await saveConsent([...cookieSettingCategories], origin);
    if (!skipLoadingState) {
      setIsSavingSettings(false);
    }
  };

  return {
    consentSettings,
    dispatch,
    saveSettings,
    isSavingSettings,
    acceptAll,
    oldConsentData,
  };
};

export const getMarketingConsent = () => getConsentData().includes('marketing');
export const getPersonalizationConsent = () => getConsentData().includes('personalization');
export const getStatisticsConsent = () => getConsentData().includes('statistics');

export const getOtherConsent = () => getConsentData().includes('other');
export const getYoutubeConsent = () => getConsentData().includes('other');
export const getFreshchatConsent = () => getConsentData().includes('other');

export const getDidReceiveConsent = () => typeof Cookies.get('consent-data-v2') !== 'undefined';
