'use client';

import { useState } from 'react';
import { LoginModal } from 'components/Auth/LoginModal';
import { gtmService } from '../util/gtm-service';

export const useLoginModal = (defaultOpen: boolean = false, onSuccess = () => {}) => {
  const [isModalOpen, setIsModalOpen] = useState(defaultOpen);

  const Modal = () =>
    isModalOpen ? (
      <LoginModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} onSuccess={onSuccess} />
    ) : null;

  const openLoginModal = () => {
    setIsModalOpen(true);
    gtmService.event('login:open');
  };

  return {
    LoginModal: Modal,
    openLoginModal,
    closeLoginModal: () => setIsModalOpen(false),
  };
};
